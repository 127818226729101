import React from 'react';
import { createRoot } from 'react-dom/client';

import Header from './components/header';
import Shapes from './components/shapes/app';

const target = document.getElementById('root');

(() => {
  if (target) {
    const root = createRoot(target);

    root.render(
      <>
        <Header />
        <Shapes />
      </>
    );
  }
})()
