import React, { Component } from 'react';

import {
  SHAPE_HUES,
  ShapeLiteral,
} from '../../constants';
import type { ShapeWithId } from '../../constants';

import uniq from '../../lib/array/uniq';
import setClasses from '../../lib/dom/set-classes';
import randomInt from '../../lib/number/random-int';

import Circle from './circle';
import Square from './square';
import Trapezoid from './trapezoid';
import Triangle from './triangle';

const getRandomHue = () => SHAPE_HUES[randomInt(SHAPE_HUES.length)];

const COMPONENTS = {
  [ShapeLiteral.CIRCLE]: Circle,
  [ShapeLiteral.SQUARE]: Square,
  [ShapeLiteral.TRAPEZOID]: Trapezoid,
  [ShapeLiteral.TRIANGLE]: Triangle,
};

interface Props {
  shapes: ShapeWithId[];
}

export default class Row extends Component<Props> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className='shapes'>
        {this.props.shapes.map((s, i) => {
          const { direction, id, name } = s;
          const ShapeComponent = COMPONENTS[name];
          const hue = getRandomHue();
          const className = setClasses(...uniq([name, id]), hue);

          return (
            <div key={i} className={className}>
              <ShapeComponent direction={direction} />
            </div>
          );
        })}
      </div>
    );
  }
}
