import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import times from 'lodash/times';

import {
  MAX_SHAPES_PER_ROW,
  SHAPE_HEIGHT,
  ShapeLiteral,
  SHAPES,
} from '../../constants';
import type {
  Shape,
  ShapeWithId,
  ShapeNextOption,
} from '../../constants';

import randomInt from '../../lib/number/random-int';

import Row from './row';

const getRandomShape = (shapes: ShapeWithId[]) => shapes[randomInt(shapes.length)];

const getNewShape = (shapes: ShapeWithId[], maxWeight: number = 3, next: ShapeNextOption[] = []) => {
  shapes = shapes.filter(shape => shape.weight <= maxWeight);

  if (next.length) {
    shapes = shapes.filter(shape => {
      if (shape.direction) {
        return next.some(n => {
          return n.name === shape.name && n.direction === shape.direction;
        });
      } else {
        return next.some(n => {
          return n.name === shape.name;
        });
      }
    });
  }

  return getRandomShape(shapes);
};

type Tally = Record<ShapeLiteral, number>;

const tallyShape = (tally: Tally, shapeName: ShapeLiteral) => {
  tally[shapeName] = shapeName in tally
    ? tally[shapeName] + 1
    : tally[shapeName] = 1;

  return tally;
};

const getRowCount = () => Math.ceil(window.innerHeight / SHAPE_HEIGHT) + 1;

const getRow = (shapes: ShapeWithId[]): ShapeWithId[] => {
  const rowShapes = [] as ShapeWithId[];
  const excludedShapes = [] as ShapeLiteral[];
  let rowWeight = 0;
  let next = [] as ShapeNextOption[];
  let shapeCount = {} as Tally;
  let shape = null;

  do {
    shapes = shapes.filter(s => !excludedShapes.includes(s.name));
    shape = getNewShape(shapes, MAX_SHAPES_PER_ROW - rowWeight, next);
    rowWeight = rowWeight + shape.weight;
    next = shape.next;

    shapeCount = tallyShape(shapeCount, shape.name);

    if (shape.maxPerRow === shapeCount[shape.name]) {
      excludedShapes.push(shape.name);
    }

    rowShapes.push(shape);
  } while (rowWeight < MAX_SHAPES_PER_ROW);

  return rowShapes;
};

const getId = (item: Shape) => [item.name, item.direction].filter(Boolean).join('-');

const getShapes = () => {
  const shapes = {} as Record<string, ShapeWithId>;

  SHAPES.forEach(shape => {
    if (shape.orientations?.length) {
      shape.orientations.forEach(orientation => {
        const id = getId({
          ...orientation,
          maxPerRow: shape.maxPerRow,
          name: shape.name,
          weight: shape.weight,
        });

        shapes[id] = {
          ...shape,
          ...orientation,
          id,
        };
      });
    } else {
      const id = getId(shape);

      shapes[id] = {
        ...shape,
        id,
      };
    }

    return shape;
  });

  return Object.values(shapes);
};

const shapes = getShapes();

interface Props {}

interface State {
  rowCount: number;
}

export default class Shapes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      rowCount: getRowCount(),
    };

    window.addEventListener('resize', debounce(this.setRowCount, 250));
  }

  setRowCount = () => {
    const rowCount = getRowCount();

    if (rowCount > this.state.rowCount) {
      this.setState({ rowCount: rowCount + 1 });
    }
  };

  render() {
    const { rowCount } = this.state;

    return (
      <div className='shapes-area active'>
        {times(rowCount, i => (
          <Row key={i} shapes={getRow(shapes)} />
        ))}
      </div>
    );
  }
}
