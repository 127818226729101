import React from 'react';

const Header = () => {
  return (
    <header className='global'>
      <h1><a href='/'>Justin Skolnick</a> lives in Portland.</h1>
      
      <nav className='global'>
        <ul className='onsite'>
          <li className='nav-mail'><span className='nav-description'>Contact</span><a href='mailto:mail@justinskolnick.com'>Mail</a></li>
        </ul>
      </nav>
  
      <nav className='social'>
        <dl>
          <dt>Social</dt>
          <dd className='document'><a href='/files/justinskolnick.pdf'>Résumé</a> <span className='details'>49k PDF</span></dd>
          <dd className='network'><a href='https://www.linkedin.com/in/justinskolnick' title='LinkedIn'>LinkedIn</a></dd>
          <dd className='network'><a href='https://github.com/justinskolnick' title='GitHub'>GitHub</a></dd>
          <dd className='network'><a href='https://www.instagram.com/justinskolnick/' title='Instagram'>Instagram</a></dd>
        </dl>
      </nav>
    </header>
  );
};

export default Header;
