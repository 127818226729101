import React from 'react';

import { Direction } from '../../constants';

interface Props {
  direction: Direction;
}

const Trapezoid = ({ direction }: Props) => (
  <svg width='300' height='100' viewBox='0 0 300 100' xmlns='http://www.w3.org/2000/svg'>
    {direction === Direction.NORTH && <path d='M0 0h300L200 100H100z'/>}
    {direction === Direction.SOUTH && <path d='M100 0h100l100 100H0z'/>}
  </svg>
);

export default Trapezoid;
