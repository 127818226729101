export const MAX_SHAPES_PER_ROW = 13;
export const SHAPE_HEIGHT = 48;

export enum Hue {
  BLANK = 'blank',
  DARK = 'dark',
  DARKER = 'darker',
  LIGHT = 'light',
  LIGHTER = 'lighter',
  MEDIUM = 'medium',
}

export enum ShapeLiteral {
  CIRCLE = 'circle',
  SQUARE = 'square',
  TRAPEZOID = 'trapezoid',
  TRIANGLE = 'triangle',
}

export enum Direction {
  NORTH = 'n',
  SOUTH = 's',
  NORTHEAST = 'ne',
  NORTHWEST = 'nw',
  SOUTHEAST = 'se',
  SOUTHWEST = 'sw',
}

export type ShapeNextOption = {
  direction?: Direction;
  name: ShapeLiteral;
};

type ShapeOrientation = {
  direction: Direction;
  next: ShapeNextOption[];
}

type ShapeDefinition = {
  direction?: Direction;
  maxPerRow?: number;
  name: ShapeLiteral;
  next?: ShapeNextOption[];
  orientations?: ShapeOrientation[];
  weight: number;
};

export type Shape = ShapeDefinition;
export type ShapeWithId = Shape & {
  id: string;
}

export const SHAPE_HUES = [
  Hue.BLANK,
  Hue.BLANK,
  Hue.LIGHTER,
  Hue.LIGHT,
  Hue.MEDIUM,
  Hue.DARK,
  Hue.LIGHTER,
  Hue.BLANK,
  Hue.BLANK,
] as Hue[];

export const SHAPES = [
  {
    direction: null,
    maxPerRow: null,
    name: ShapeLiteral.CIRCLE,
    next: [
      {
        name: ShapeLiteral.TRIANGLE,
        direction: Direction.NORTHWEST,
      },
      {
        name: ShapeLiteral.TRIANGLE,
        direction: Direction.SOUTHWEST,
      },
      {
        name: ShapeLiteral.SQUARE,
      },
    ],
    weight: 1,
  },
  {
    direction: null,
    maxPerRow: null,
    name: ShapeLiteral.SQUARE,
    next: [
      {
        name: ShapeLiteral.TRIANGLE,
        direction: Direction.NORTHWEST,
      },
      {
        name: ShapeLiteral.TRIANGLE,
        direction: Direction.SOUTHWEST,
      },
    ],
    weight: 1,
  },
  {
    maxPerRow: 2,
    name: ShapeLiteral.TRAPEZOID,
    orientations: [
      {
        direction: Direction.NORTH,
        next: [
          {
            name: ShapeLiteral.TRAPEZOID,
            direction: Direction.SOUTH,
          },
          {
            name: ShapeLiteral.TRIANGLE,
            direction: Direction.SOUTHEAST,
          },
          {
            name: ShapeLiteral.SQUARE,
          },
        ]
      },
      {
        direction: Direction.SOUTH,
        next: [
          {
            name: ShapeLiteral.TRAPEZOID,
            direction: Direction.NORTH,
          },
          {
            name: ShapeLiteral.TRIANGLE,
            direction: Direction.NORTHEAST,
          },
          {
            name: ShapeLiteral.SQUARE,
          },
        ]
      },
    ],
    weight: 3,
  },
  {
    maxPerRow: null,
    name: ShapeLiteral.TRIANGLE,
    orientations: [
      {
        direction: Direction.NORTHEAST,
        next: [
          {
            name: ShapeLiteral.TRIANGLE,
            direction: Direction.NORTHWEST,
          },
          {
            name: ShapeLiteral.TRIANGLE,
            direction: Direction.SOUTHWEST,
          },
          {
            name: ShapeLiteral.SQUARE,
          },
        ],
      },
      {
        direction: Direction.NORTHWEST,
        next: [
          {
            name: ShapeLiteral.TRIANGLE,
            direction: Direction.SOUTHEAST,
          },
          {
            name: ShapeLiteral.TRAPEZOID,
            direction: Direction.SOUTH,
          },
          {
            name: ShapeLiteral.CIRCLE,
          },
        ],
      },
      {
        direction: Direction.SOUTHEAST,
        next: [
          {
            name: ShapeLiteral.TRIANGLE,
            direction: Direction.SOUTHWEST,
          },
          {
            name: ShapeLiteral.TRIANGLE,
            direction: Direction.NORTHWEST,
          },
          {
            name: ShapeLiteral.SQUARE,
          },
        ],
      },
      {
        direction: Direction.SOUTHWEST,
        next: [
          {
            name: ShapeLiteral.TRIANGLE,
            direction: Direction.NORTHEAST,
          },
          {
            name: ShapeLiteral.TRAPEZOID,
            direction: Direction.SOUTH,
          },
        ],
      }
    ],
    weight: 1,
  }
] as Shape[];
