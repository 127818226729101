import React from 'react';

import { Direction } from '../../constants';

interface Props {
  direction: Direction;
}

const Triangle = ({ direction }: Props) => (
  <svg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
    {direction === Direction.NORTHWEST && <path d='M0 0h100L0 100z'/>}
    {direction === Direction.SOUTHWEST && <path d='M0 0l100 100H0z'/>}
    {direction === Direction.NORTHEAST && <path d='M0 0h100v100z'/>}
    {direction === Direction.SOUTHEAST && <path d='M100 0v100H0z'/>}
  </svg>
);

export default Triangle;
